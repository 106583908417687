import "./advertising.scss";
import React from "react";
import card from "./img/cards.png";

const Advertising = () => (
	<>
		<section className="full-width advertising">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="advertising__header">По вопросам рекламы</h2>
					</div>

					<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
						<p className="advertising__text">
							АО «Транспортная карта» - оператор « Автоматизированной системы
							оплаты проезда на городском пассажирском транспорте». Основная
							цель внедрения АСОП ГПТ – развитие отрасли пассажирских
							транспортных перевозок г. Казани путем внедрения безналичной
							системы оплаты проезда, а так же создание совмещенной банковской
							карты с транспортным приложением. АО «Транспортная карта»
							предоставляет эксклюзивную услугу – размещение Вашей рекламы на
							проездных билетах общественного транспорта г.Казани. Реклама на
							транспортных картах – эффективный инструмент рекламы собственных
							услуг, а также установление обратной связи между Вашей компанией и
							покупателями;
						</p>

						<ul className="advertising__list">
							<li>
								— Вы имеете возможность в течение длительного времени
								воздействовать на конкретную целевую аудиторию (несколько часов
								/ дней, в отличие от секунд, которые тратит зритель на просмотр
								других видов рекламы);
							</li>
							<li>
								— Пассажир, купивший карту с рекламой, уже не может обменять ее,
								при этом Ваши адреса и телефоны в течение всего «срока службы»
								билета находятся «в кармане» у покупателя Ваших товаров и услуг;
							</li>
							<li>
								- Пассажир не может не заметить и не прочитать Ваше рекламное
								сообщение, гарантирован контакт с рекламным объявлением;
							</li>
							<li>
								- Формат эстетичной «визитки» - 85х55мм - удобен для
								использования и хранения.
							</li>
						</ul>

						<h4 className="advertising__title">
							Тарифы по размещению рекламы на транспортных картах г.Казани.
						</h4>

						<ul className="advertising__list">
							<li>
								1) Реклама размещается на лицевой и оборотной стороне карты.
								Обязательные элементы транспортной карты – логотип транспортной
								карты (в нижней части), слева - герб города Казани.
							</li>
							<li>
								2) Размеры рекламного модуля составляют:
								<div>
									- лицевая сторона – <strong>60*40мм;</strong>
								</div>
								<div>
									- оборотная сторона – <strong>85*55мм;</strong>
								</div>
							</li>
							<li>
								3) Транспортная карта может выглядеть следующим образом:
								<img
									alt="Пример транспортной карты"
									src={card}
									className="advertising__image-card"
								/>
							</li>
							<li>
								4) Требования к макету:
								<div>
									макет на лицевой стороне должен вписываться в цветовую гамму
									лицевой стороны карты (обязательные элементы могут быть
									переданы при разработке макета).
								</div>
							</li>
							<li>
								5) Стоимость размещения рекламы на транспортной карте
								определяется соглашением сторон.
								<div>
									Примечания: выход в тираж не менее 8 недель по согласованию.
								</div>
							</li>
						</ul>

						<h4 className="advertising__title">Контактная информация</h4>

						<p className="advertising__text">
							Получить консультацию по всем предоставляемым услугам компании и
							обсудить дополнительные условия Вы можете по телефону:
							<br />
							<strong>
								<a href={`tel:+7 (843) 2-111-462`}>
									&nbsp;+7&nbsp;(843)&nbsp;2-111-462&nbsp;
								</a>
							</strong>{" "}
							или отправив письмо на эл. адрес:{" "}
							<strong>
								<a href="mailto:office@transkart.ru">office@transkart.ru</a>
							</strong>
						</p>
					</div>
				</div>
			</div>
		</section>
	</>
);

export default Advertising;
